import * as React from "react";
import Quote from "../components/quote";
import Frame from "../components/frame";
import Highlight from "../components/highlight";

export default function Index() {
    return (
        <Frame>
            <header>
                <div className="container">
                    <h1>
                        Hey 👋
                        <br/>
                        I'm Hieu, <Highlight>a developer</Highlight> {}
                        from {}
                        <Highlight secondary>Vinh</Highlight>
                    </h1>
                    <p>
                        Precisely, I'm a fullstack e-commerce developer. I mainly work with {}
                        <Highlight>Magento</Highlight> and <Highlight secondary>Wordpress</Highlight>.
                    </p>
                </div>
            </header>
            <section>
                <div className="container">
                    <Quote/>
                </div>
            </section>
        </Frame>
    )
}
