import * as React from "react";

export default function Quote() {
    return (
        <blockquote>
            Always feel like a kid with everything, the not yet known, the passions, the urges to find out.
            That manner, keeps me moving forward.
        </blockquote>
    )
}
